// import Turbolinks from "turbolinks";
require("trix");
require("@rails/actiontext");
import Rails from "rails-ujs";
import * as ActiveStorage from "activestorage";

import Appsignal from "@appsignal/javascript";
import { installErrorHandler } from "@appsignal/stimulus"

let appsignal
if(process.env.NODE_ENV == 'production') {
  appsignal = new Appsignal({
      key: "d5845ac0-6fae-4739-9545-ed00da5652d3"
  })
}


import "bootstrap";
import "air-datepicker";
import "air-datepicker/dist/js/i18n/datepicker.fr.js";
import "air-datepicker/dist/css/datepicker.min.css";
import "./static/riing_favicon_32x32.png";

import "./index.css";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import { initAutocomplete } from "./init_autocomplete";

const application = Application.start();

if(process.env.NODE_ENV == 'production') {
  installErrorHandler(appsignal, application)
}

const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

$(function() {
  $('[data-toggle="tooltip"]').tooltip();
});

$(".alert-info").fadeOut(5000);

Rails.start();

ActiveStorage.start();

initAutocomplete();

const selectInvoice = () => {
  const triggers = document.getElementsByClassName("triggers");
  for (let item of triggers) {
    item.onclick = e => {
      const parent = item.parentElement;
      parent.classList.toggle("selected");
      if (parent.classList.contains("selected")) {
        parent.lastChild.style.cssText = `height:${parent.clientHeight *
          1.6}px;width:${parent.clientWidth}px;margin-left:-${
          parent.clientWidth
        }px;margin-top:${parent.clientHeight}px;`;
      } else {
        parent.lastChild.style.cssText = `height:0;width:0;margin-left:0;margin-top:0;`;
      }
    };
  }
};

MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

var observer = new MutationObserver(function(mutations, observer) {
  selectInvoice();
});

var refElem = document.getElementById("js-invoices__invoices-root");

if (refElem) {
    observer.observe(document.getElementById("js-invoices__invoices-root"), {
    subtree: true,
    attributes: true
    //...
  });
};

var needObserver = new MutationObserver(function(mutations, observer) {
  $('.datepicker-here').datepicker();
});

var needElem = document.getElementById("chatList");

if (needElem) {
    needObserver.observe(needElem, {
    subtree: true,
    attributes: true
    //...
  });
};
